$(document).ready(function () {
  function removeLoading(ms) {
    return new Promise((resolve) => {
      $("#homepage .newsletter-loading-form").css({'display': 'none'});

      $('#homepage .response-form').empty();
      $('#homepage .response-form').css({'display': 'block'});
      setTimeout(resolve, ms);
    });
  }

  function sendMail(_email, _terms) {
    let data = {
      email: _email,
      terms: _terms,
      submittedLanguage: 'pt',
    };

    window.newsletter.sendMail(data, (response) => {

      if (response.status != undefined) {
        if (response.status == 'warning') {

          removeLoading(1000); //  1 seconds
          
          let alertNewsletterWarning = `
             <div class="alert alert-warning" role="alert">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
               ${trans('frontend.rgpd-warning')}
             </div>`;

          $('#homepage').find(' .response-form').prepend(alertNewsletterWarning);

          $('#homepage .response-form').delay(3500).fadeOut('slow');
        }

        if (response.status == 'success') {
          clearForm();

          removeLoading(1000); //  1 seconds

          let alertNewsletterSuccess = `
             <div class="alert alert-success" role="alert">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
               ${trans('frontend.rgpd-success')}
             </div>`;


          $('#homepage').find(' .response-form').prepend(alertNewsletterSuccess);

          $('#homepage .response-form').delay(3500).fadeOut('slow');
        }
      }

    }, (response) => {
      removeLoading(1000); //  1 seconds

      let alertNewsletterDanger = `
           <div class="alert alert-danger" role="alert">
              <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
              ${trans('frontend.rgpd-error')}
           </div>`;


      $('#homepage').find(' .response-form').prepend(alertNewsletterDanger);

      $('#homepage .response-form').delay(3500).fadeOut('slow');
    });
  }

  function clearForm() {
    $('#homepage #newsletter-input').val('');
    $('#homepage #newsletter-rgpd').prop("checked", false);

    $('#homepage #remove-newsletter-input').val('');

  }

  function unsubscribe(_email) {
    let data = {
      email: _email,
    };

    window.newsletter.unsubscribe(data, (response) => {
      if (response.status != undefined) {
        if (response.status == 'warning') {

          // clearForm();
          removeLoading(1000); //  1 seconds

          let alertUnsubscribeDanger = `
             <div class="alert alert-danger" role="alert">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
               ${trans('frontend.remove-rgpd-warning')}
             </div>`;

          $('#homepage').find(' .response-form').prepend(alertUnsubscribeDanger);

          $('#homepage .response-form').delay(3500).fadeOut('slow');
        }

        if (response.status == 'success') {
          clearForm();
          removeLoading(1000); //  1 seconds

          let alertUnsubscribeSuccess = `
             <div class="alert alert-success" role="alert">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
               ${trans('frontend.remove-rgpd-success')}
             </div>`;

          $('#homepage').find(' .response-form').prepend(alertUnsubscribeSuccess);

          $('#homepage .response-form').delay(3500).fadeOut('slow');
        }
      }

    }, (response) => {
      removeLoading(1000); //  1 seconds
      let alertUnsubscribeDanger = `
             <div class="alert alert-danger" role="alert">
                <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
               ${trans('frontend.remove-rgpd-warning')}
             </div>`;

      $('#homepage').find(' .response-form').prepend(alertUnsubscribeDanger);

      $('#homepage .response-form').delay(3500).fadeOut('slow');
    });
  }

  //CLICK do SPAN para adicionar o email da newsletter
  $('#homepage ').on('click', ' #subscribe-newsletter', function () {
    $("#homepage .newsletter-loading-form").css({'display': 'flex'});
    let email = $('#homepage #newsletter-input').val();
    let terms = $('#homepage #newsletter-rgpd').prop("checked") ? '1' : '0';

    sendMail(email, terms);
  });

  //CLICK do SPAN para remover o email da newsletter
  $('#homepage').on('click', '  #unsubscribe-newsletter', function () {
    $("#homepage .newsletter-loading-form").css({'display': 'flex'});

    let email = $('#homepage #remove-newsletter-input').val();

    unsubscribe(email);
  });

  //Mostra o layout para remover o email da newsletter
  $('#homepage ').on('click', ' .remove-from-newsletter', function () {
    $('#homepage .subscribe-newsletter, .remove-from-newsletter').css({'display': 'none'});
    $('#homepage .unsubscribe-newsletter, .add-newsletter').css({'display': 'flex'});

    $('#homepage .response-form').empty();

    $('#homepage .second-block').find('.title-section').text(`${trans('frontend.remove-subscription-newsletter')}`);
  });

  //Mostra o layout para adicionar o email da newsletter
  $('#homepage ').on('click', ' .add-newsletter', function () {
    $('#homepage .subscribe-newsletter').css({'display': 'block'});
    $('#homepage .remove-from-newsletter').css({'display': 'flex'});
    $('#homepage .unsubscribe-newsletter, .add-newsletter').css({'display': 'none'});

    $('#homepage .response-form').empty();

    $('#homepage .second-block').find('.title-section').text(`${trans('frontend.subscribe-newsletter')}`);
  });

});